  export interface Service {
    title: string;
    description: string;
    aosDelay: number;
    pricePerDay: number;
    postProductionPricePerDay: number;
    supOperatorPrice?: number;
  }
  
  export interface ServiceCategory {
    title: string;
    services: Service[];
    icon?: string;
  }

  export interface ServiceTitle {
    value: string;
    label: string;
  }

  export type SelectedService = {
    service: Service;
    productionDays: number;
    postProductionOption: 'simple' | 'multiple';
    additionalOperators: number; // Nombre d'opérateurs supplémentaires pour ce service
  };
  
  export const isVideoCategory = (categoryTitle: string): boolean => {
    return categoryTitle.toLowerCase().includes('vidéo');
  };
  
  // export const extractServiceTitles = (categories: ServiceCategory[]): ServiceTitle[] => {
  //   const titles: ServiceTitle[] = [];
  
  //   categories.forEach(category => {
  //     const categoryPrefix = isVideoCategory(category.title) ? 'Vidéo' : '';
  
  //     category.services.forEach(service => {
  //       titles.push({
  //         value: `${categoryPrefix} ${service.title}`,
  //         label: `${categoryPrefix} ${service.title}`,
  //       });
  //     });
  //   });
  
  //   return titles;
  // };
  
 // servicesData.ts

export const serviceEntreprisesCategories: ServiceCategory[] = [
  {
    title: 'Production vidéo',
    services: [
      {
        title: 'Présentation de l\'entreprise',
        description: 'Vidéo expliquant votre histoire, valeurs et mission.',
        aosDelay: 100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Interview des dirigeants',
        description: 'Interviews des dirigeants pour présenter leur vision et stratégie.',
        aosDelay: 300,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Démonstration de produits',
        description: 'Vidéos montrant les fonctionnalités et avantages des produits.',
        aosDelay: 400,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Présentation de services',
        description: 'Vidéos explicatives des services proposés.',
        aosDelay: 500,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Tutoriels d\'utilisation',
        description: 'Tutoriels pour aider à utiliser vos produits ou services.',
        aosDelay: 600,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Couverture de séminaires',
        description: 'Vidéos capturant l\'essence de vos séminaires.',
        aosDelay: 700,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Lancement de produit',
        description: 'Vidéos documentant le lancement de vos produits.',
        aosDelay: 800,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Activités de team building',
        description: 'Vidéos montrant la cohésion et l\'engagement des équipes.',
        aosDelay: 900,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Spot publicitaire',
        description: 'Vidéos publicitaires pour promouvoir vos produits ou services.',
        aosDelay: 1000,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Annonce spéciale',
        description: 'Vidéos pour des promotions ou événements importants.',
        aosDelay: 1100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Campagne marketing',
        description: 'Vidéos pour booster vos campagnes marketing.',
        aosDelay: 1200,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
    ],
  },
  {
    title: 'Services en photographie',
    services: [
      {
        title: 'Photos de portraits professionnels',
        description: 'Portraits professionnels de haute qualité pour les employés.',
        aosDelay: 100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de produits',
        description: 'Photos détaillées et attrayantes de vos produits.',
        aosDelay: 200,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos événementielles',
        description: 'Photos professionnelles pour les séminaires et conférences.',
        aosDelay: 300,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de l\'environnement de travail',
        description: 'Photos de vos bureaux et espaces de travail.',
        aosDelay: 400,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de communication',
        description: 'Photos percutantes pour brochures et supports marketing.',
        aosDelay: 500,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
    ],
  },
];

export const serviceArtsCategories: ServiceCategory[] = [
  {
    title: 'Clips Musicaux',
    services: [
      {
        title: 'Réalisation de clips musicaux',
        description: 'Création de clips sur mesure reflétant votre univers musical, de l’écriture au montage final.',
        aosDelay: 100,
        pricePerDay: 700,
        postProductionPricePerDay: 600,
        supOperatorPrice: 400,
      },
      {
        title: 'Teasers musicaux',
        description: 'Courtes vidéos percutantes pour promouvoir vos singles ou albums.',
        aosDelay: 200,
        pricePerDay: 600,
        postProductionPricePerDay: 500,
        supOperatorPrice: 350,
      },
      {
        title: 'Sessions live studio',
        description: 'Captation et production de performances en studio pour un rendu acoustique authentique.',
        aosDelay: 300,
        pricePerDay: 800,
        postProductionPricePerDay: 600,
        supOperatorPrice: 400,
      },
      {
        title: 'Lyric videos',
        description: 'Vidéos illustrant les paroles de vos chansons pour engager votre public.',
        aosDelay: 400,
        pricePerDay: 500,
        postProductionPricePerDay: 400,
        supOperatorPrice: 300,
      },
      {
        title: 'Performance créative',
        description: 'Mise en scène et captation unique pour sublimer vos talents musicaux.',
        aosDelay: 500,
        pricePerDay: 900,
        postProductionPricePerDay: 700,
        supOperatorPrice: 450,
      },
    ],
  },
  {
    title: 'Événements Musicaux et Spectacles',
    services: [
      {
        title: 'Captation de concerts',
        description: 'Enregistrement multi-caméras de vos performances live avec un rendu professionnel.',
        aosDelay: 100,
        pricePerDay: 1000,
        postProductionPricePerDay: 800,
        supOperatorPrice: 500,
      },
      {
        title: 'Aftermovies de festivals',
        description: 'Résumé dynamique et immersif pour immortaliser vos événements musicaux.',
        aosDelay: 200,
        pricePerDay: 800,
        postProductionPricePerDay: 600,
        supOperatorPrice: 400,
      },
      {
        title: 'Streaming live',
        description: 'Diffusion en direct de vos concerts ou showcases avec une qualité irréprochable.',
        aosDelay: 300,
        pricePerDay: 1200,
        postProductionPricePerDay: 0,
        supOperatorPrice: 500,
      },
      {
        title: 'Vidéos promotionnelles',
        description: 'Spots vidéos pour annoncer vos prochains événements musicaux ou spectacles.',
        aosDelay: 400,
        pricePerDay: 600,
        postProductionPricePerDay: 500,
        supOperatorPrice: 350,
      },
      {
        title: 'Documentaires musicaux',
        description: 'Récits visuels sur l’histoire et les coulisses de vos projets artistiques.',
        aosDelay: 500,
        pricePerDay: 900,
        postProductionPricePerDay: 800,
        supOperatorPrice: 450,
      },
    ],
  },
];

export const serviceMariagesCategories: ServiceCategory[] = [{
  title: "Nos Services pour les Mariages en Photo et Vidéo",
  icon: "💍",
  services: [
      {
          title: "Reportage Photo de Mariage",
          description: "Des clichés intemporels pour immortaliser les moments clés de votre grand jour, du début à la fin.",
          aosDelay: 100,
          pricePerDay: 1200,
          postProductionPricePerDay: 500
      },
      {
          title: "Vidéo de Mariage",
          description: "Un film cinématographique pour revivre les émotions et les moments inoubliables de votre journée.",
          aosDelay: 200,
          pricePerDay: 1500,
          postProductionPricePerDay: 600
      },
      {
          title: "Séance Engagement (Pré-Mariage)",
          description: "Immortalisez vos moments avant le mariage avec une séance photo ou vidéo créative.",
          aosDelay: 300,
          pricePerDay: 800,
          postProductionPricePerDay: 400
      },
      {
          title: "Clip Teaser de Mariage",
          description: "Un court-métrage dynamique pour teaser le film complet de votre mariage.",
          aosDelay: 400,
          pricePerDay: 600,
          postProductionPricePerDay: 250
      },
      {
          title: "Couverture Live de Mariage",
          description: "Diffusion en direct de votre cérémonie ou réception pour que vos proches éloignés puissent vivre le moment.",
          aosDelay: 500,
          pricePerDay: 1000,
          postProductionPricePerDay: 300
      },
      {
          title: "Album Photo de Mariage",
          description: "Création d'un album photo personnalisé pour conserver un souvenir unique et intemporel.",
          aosDelay: 600,
          pricePerDay: 700,
          postProductionPricePerDay: 250
      },
      {
          title: "Montage Vidéo et Highlight",
          description: "Un montage vidéo sur mesure avec les moments forts de votre mariage.",
          aosDelay: 700,
          pricePerDay: 800,
          postProductionPricePerDay: 300
      },
      {
          title: "Consultation et Planification",
          description: "Conseils et assistance pour planifier les prises de vue, les lieux, et les moments à capturer.",
          aosDelay: 800,
          pricePerDay: 500,
          postProductionPricePerDay: 0
      }
  ]
}];


export type Materiel = {
  id: number;
  title: string;
  images: string[];
  description: string;
  price: number;
  quantity: number;
};

export type MaterielCategory = {
  category: string; // Nom de la catégorie
  items: Materiel[]; // Liste des articles dans cette catégorie
};

export type Props = {
  categories: MaterielCategory[];
};

export const materielCategories: MaterielCategory[] = [
  {
    category: 'Caméras',
    items: [
      { id: 1, title: 'Sony A7IV', images: ['/images/camera/sony-a7iv.jpg'], description: 'Caméra hybride 4K - 60fps', quantity: 2, price: 150 },
      { id: 2, title: 'Sony A7SIII', images: ['/images/camera/sony-a7siii.jpg'], description: 'Caméra hybride haute sensibilité 4K - 120fps', quantity: 1, price: 200 },
      { id: 3, title: 'Fuji XT4', images: ['/images/camera/fuji-xt4.jpg'], description: 'Caméra APS-C 4K - 60fps', quantity: 1, price: 100 },
    ],
  },
  {
    category: 'Lumières',
    items: [
      { id: 4, title: 'GVM 80W Bi-Color', images: ['/images/lumiere/gvm-80w.jpg'], description: 'Lampe LED 80W', quantity: 2, price: 50 },
      { id: 5, title: 'GVM 650B', images: ['/images/lumiere/gvm-650b.jpg'], description: 'Lampe LED 650B', quantity: 2, price: 80 },
      { id: 6, title: 'Tube LED RGB Viltrox', images: ['/images/lumiere/tube-rgb.jpg'], description: 'Tube LED RGB', quantity: 2, price: 40 },
      { id: 7, title: 'Spotlight Neewer 20°', images: ['/images/lumiere/neewer-spotlight.jpg'], description: 'Spotlight 20°', quantity: 1, price: 30 },
      { id: 5, title: 'Flash Cobra', images: ['/images/lumiere/flash-cobra.jpg'], description: 'Éclairage flash puissant et réglable pour des photos professionnelles.', quantity: 1, price: 100 },
    ],
  },
  {
    category: 'Objectifs',
    items: [
      { id: 8, title: 'Tamron 28-75mm F2.8', images: ['/images/objectifs/tamron-28-75.jpg'], description: 'Zoom polyvalent', quantity: 2, price: 50 },
      { id: 10, title: 'Viltrox 16mm F1.8', images: ['/images/objectifs/16mm.jpg'], description: 'Grand angle lumineux', quantity: 1, price: 30 },
      { id: 11, title: 'Helio 58mm F2', images: ['/images/objectifs/helio-58mm.jpg'], description: 'Objectif vintage', quantity: 1, price: 20 },
      { id: 9, title: '135mm F2.8', images: ['/images/objectifs/135mm.jpg'], description: 'Téléobjectif compact', quantity: 1, price: 40 },
      { id: 12, title: '400mm F4', images: ['/images/objectifs/400mm.jpg'], description: 'Téléobjectif longue portée', quantity: 1, price: 60 },
    ],
  },
  {
    category: 'Son',
    items: [
      { id: 14, title: 'Sennheiser MKE600', images: ['/images/son/mke600.jpg'], description: 'Microphone directionnel idéal pour court-métrage', quantity: 1, price: 30 },
      { id: 16, title: 'DJI Mic HF Kit', images: ['/images/son/dji-hf.jpg'], description: 'Kit micro sans fil - 2 émetteurs', quantity: 1, price: 40 },
      { id: 13, title: 'Rode VideoMic', images: ['/images/son/rode-videomic.jpg'], description: 'Microphone compact', quantity: 1, price: 20 },
      { id: 17, title: 'Micro cravate', images: ['/images/son/micro-cravate.jpg'], description: 'Micro cravate discret', quantity: 3, price: 15 },
      { id: 15, title: 'Zoom H1N', images: ['/images/son/zoom-h1n.jpg'], description: 'Enregistreur portable', quantity: 1, price: 25 },
    ],
  },
  {
    category: 'Batteries',
    items: [
      { id: 18, title: 'Batterie Sony', images: ['/images/batteries/sony.jpg'], description: 'Batterie pour caméras Sony', quantity: 10, price: 10 },
      { id: 19, title: 'Batterie Fuji', images: ['/images/batteries/fuji.jpg'], description: 'Batterie pour Fujifilm XT4', quantity: 5, price: 10 },
      { id: 20, title: 'Batterie NP980L', images: ['/images/batteries/np980l.jpg'], description: 'Batterie longue durée pour moniteur et lumière', quantity: 2, price: 15 },
    ],
  },
  {
    category: 'Accessoires',
    items: [
      { id: 28, title: 'Follow Focus PD Movie HF', images: ['/images/accessoires/follow-focus.jpg'], description: 'Contrôle précis de la mise au point', quantity: 1, price: 60 },
      { id: 27, title: 'Moniteur Externe', images: ['/images/accessoires/monitor.jpg'], description: 'Moniteur externe pour tournage', quantity: 1, price: 50 },
      { id: 22, title: 'Crane 2S', images: ['/images/accessoires/crane-2s.jpg'], description: 'Stabilisateur motorisé', quantity: 1, price: 70 },
      { id: 23, title: 'RS3', images: ['/images/accessoires/rs3.jpg'], description: 'Stabilisateur motorisé', quantity: 1, price: 100 },
      { id: 25, title: 'Trépied Vidéo', images: ['/images/accessoires/video-tripod.jpg'], description: 'Trépied stable avec tete vidéo', quantity: 2, price: 30 },
      { id: 26, title: 'Trépied Photo', images: ['/images/accessoires/photo-tripod.jpg'], description: 'Trépied photo', quantity: 1, price: 30 },
      { id: 24, title: 'Shoulder Rig', images: ['/images/accessoires/shoulder-rig.jpg'], description: 'Épaulière pour tournage', quantity: 1, price: 50 },
      { id: 21, title: 'Carte SD 128 Go', images: ['/images/accessoires/sd-card.jpg'], description: 'Carte mémoire', quantity: 10, price: 10 },
    ],
  },
];    