import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ServicesEntreprisePage from './pages/ServicesEntreprise';
import ServicesArtsPage from './pages/ServicesArts';
import ServicesMariagePage from './pages/ServicesMariage';
import Contact from './pages/Contact';
import MentionsLegales from './pages/MentionsLegales';
import QuoteSimulator from './pages/QuoteSimulator';
import GalerieMariagePage from './pages/galeries/GalerieMariage';
import GalerieArtsPage from './pages/galeries/GalerieArts';
import GalerieEntreprisesPage from './pages/galeries/GalerieEntreprises';
import MaterielPage from './pages/Materiel';
import { materielCategories } from './services/servicesData';
const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services-entreprises" element={<ServicesEntreprisePage />} />
            <Route path="/services-arts" element={<ServicesArtsPage />} />
            <Route path="/services-mariages" element={<ServicesMariagePage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/simulateur" element={<QuoteSimulator />} />
            <Route path="/materiel" element={<MaterielPage categories={materielCategories} />} />

            {/* Galerie */}
            <Route path="/galerie/evenements" element={<GalerieMariagePage />} />
            <Route path="/galerie/arts" element={<GalerieArtsPage />} />
            <Route path="/galerie/entreprises" element={<GalerieEntreprisesPage />} />
          </Routes>
        </main>
        <Footer/>
      </div>
    </Router>
  );
};

export default App;
