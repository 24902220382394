import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Materiel, Props } from '../services/servicesData';

const MaterielPage: React.FC<Props> = ({ categories }) => {
    const [selectedMateriel, setSelectedMateriel] = useState<Materiel | null>(null);

    useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  return (
    <>
    <section
        className="relative flex items-center justify-center h-96 bg-cover bg-center"
        style={{
          backgroundImage: "url('../images/matos.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative container mx-auto px-6 flex flex-col items-center justify-center text-center z-10">
          <p className="text-5xl md:text-6xl font-bold mb-4 text-white" data-aos="fade-up">
            Matériel          
          </p>
        </div>
      </section>
    <div>
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto py-12 px-4">
        <h1 className="text-4xl font-bold text-center mb-12">Notre Matériel</h1>
        {categories.map((category) => (
          <div key={category.category} className="mb-16">
            <h2 className="text-3xl font-semibold mb-8">{category.category}</h2>
            <div className="grid gap-6 !grid-cols-2 sm:!grid-cols-2 lg:!grid-cols-5">
              {category.items.map((item) => (
                <div
                key={item.id}
                className="bg-gray-700 p-6 rounded-lg shadow-lg flex flex-col justify-between"
                data-aos="fade-right"
                onClick={() => setSelectedMateriel(item)}
              >
                <h3 className="text-2xl font-semibold mb-2">{item.title}</h3>
                <p className="text-sm mb-2">{item.description}</p>
                <p className="text-l font-bold mt-auto">
                  Unité : {item.quantity}
                </p>
              </div>
              
              ))}
            </div>
          </div>
        ))}
  
        {selectedMateriel && ( <></>
        //   <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
        //     <div className="bg-gray-800 p-8 rounded-lg w-[80vw] h-[80vh] overflow-auto">
        //       <button
        //         onClick={() => setSelectedMateriel(null)}
        //         className="absolute top-4 right-4 text-gray-400 hover:text-white"
        //       >
        //         &times;
        //       </button>
        //       <h2 className="text-l font-bold mb-4">{selectedMateriel.title}</h2>
        //       <img
        //         src={selectedMateriel.images[0]}
        //         alt={selectedMateriel.title}
        //         className="w-full h-64 object-cover rounded-lg mb-4"
        //       />
        //       <p className="mb-4">{selectedMateriel.description}</p>
        //       {/* <p className="text-l font-bold mb-2">{selectedMateriel.price} € / jour</p> */}
        //       <p className="text-l font-bold">
        //         Quantité disponible : {selectedMateriel.quantity}
        //       </p>
        //     </div>
        //   </div>
        )}
      </div>
    </div>
    </div>
    </>
  );  
};

export default MaterielPage;