import React from 'react';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';

const Footer: React.FC = () => {
  
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/contact' && (
        <div className="relative">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="!pt-8 bg-gray-900 relative container mx-auto px-6 flex flex-col items-center justify-center text-center z-10">
            <a
              href="/contact"
              className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              Contactez-nous
            </a>
          </div>
        </div>
      )}

      <footer className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-6">
          <div className="flex justify-between items-center mb-6">
            <div className="flex space-x-6">
              <a
                href="https://www.instagram.com/wide.pixel"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition duration-300 ease-in-out"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <i className="fab fa-instagram fa-2x"></i>
              </a>
              <a
                href="https://www.instagram.com/yo_shooting"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition duration-300 ease-in-out"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <i className="fab fa-instagram fa-2x"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/wide-pixel-a36926243/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition duration-300 ease-in-out"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <i className="fab fa-linkedin fa-2x"></i>
              </a>
            </div>
            <div className="text-sm text-gray-400">
              <a href="/mentions-legales" className="hover:text-white transition duration-300 ease-in-out">
                Mentions Légales
              </a>
            </div>
          </div>
          <div className="text-center text-gray-400 text-sm">
            &copy; {new Date().getFullYear()} WIDE PIXEL. Tous droits réservés.
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;